body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
}

@media (max-width: 1200px) {
  html {
      font-size: 14px !important;
  }
}

@media (max-width: 900px) {
  html {
      font-size: 13px !important;
  }
}
