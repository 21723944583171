
.hs-submit > .actions {
  text-align: center !important;
}

.actions {
  width: 100%;
  padding: 0 !important;
  margin: 2rem 0 !important;
  box-sizing: border-box !important;
}

.hs-form {
  margin: 2rem 0;
}

.hs-form-545869a5-4fc9-4f11-820f-8b89e0a1404f_534f94ac-b422-4451-9615-31053e90223a, input:not([type="image"]) {
  box-sizing: border-box !important;
}

.hs-button {
  width: 100% !important;
  height: 40px !important;
  text-shadow: none !important;
}

